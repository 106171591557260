import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Pierce({ styles, ...props }) {
  const Elements = useComponents()
  const card_radius = styles.forElement("card").borderRadius
  const heading_underline = styles.forElement("heading").backgroundColor

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-wrapper">
        <div
          style={{
            backgroundColor: styles.forElement("page_image").backgroundColor
          }}
          className="formkit-bg-color"
        />
        <div
          style={styles.forElement("page_image")}
          className="formkit-bg-image"
        />
        <div
          className="formkit-container"
          style={{
            "--radius": card_radius + "px"
          }}
        >
          <svg
            className="formkit-svg-right"
            style={styles.forElement("svg")}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 40 301.4"
            xmlSpace="preserve"
          >
            <pattern
              fill="transparent"
              x="-39.2"
              y="352.8"
              width="8"
              height="8"
              patternUnits="userSpaceOnUse"
              id="Dots"
              viewBox="0 -8 8 8"
            >
              <g>
                <rect y="-8" className="st0" width="8" height="8" />
                <rect y="-8" className="st0" width="8" height="8" />
                <circle
                  className="st1"
                  cx="4"
                  cy="-4"
                  r="2"
                  fill="currentColor"
                />
              </g>
            </pattern>
            <pattern
              id="SVGID_1_"
              xlinkHref="#Dots"
              patternTransform="matrix(-0.848 0.5299 -0.5299 -0.848 -19446.6113 -11354.6191)"
            ></pattern>
            <path
              className="st2"
              fill="url(#SVGID_1_)"
              d="M0,0v301.4c30.4-31,14.6-86,19.5-134.8C23.3,113.9,49.2,66.8,36.6,29C26.8,8.2,14.3,0.7,0,0z"
            />
          </svg>
          <Elements.Image
            className="formkit-main-image"
            name="main_image"
            size={{ w: 475, h: 900 }}
            defaults={{
              src:
                "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/wUNNLdeXx2DKgZfW6mqT3P"
            }}
          />
          <div
            className="formkit-form-container"
            style={styles.forElement("form_background")}
          >
            <Elements.Image
              className="formkit-logo"
              name="logo"
              size={{ w: 100, h: 25 }}
              defaults={{
                src:
                  "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/p8XKLhWpM25vJKTotCNFfS"
              }}
            />
            <div
              style={{
                "--underline": heading_underline
              }}
            >
              <Elements.Heading
                className="formkit-heading"
                name="heading"
                defaults={{
                  content: "Costume Design Behind-the-Scenes"
                }}
              />
            </div>
            <Elements.Region className="formkit-content" name="content">
              <Elements.Content
                defaults={{
                  content:
                    "<p>Sign up for access to behind-the-scenes content of costume design and see the processes behind your favourite scenes!</p>"
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Sign Me Up!" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              className="formkit-disclaimer"
              name="disclaimer"
              defaults={{
                content:
                  "<p>I respect your privacy. Unsubscribe at any time.</p>"
              }}
            />
          </div>
          <svg
            className="formkit-svg-left"
            style={styles.forElement("svg")}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 60 328.3"
            xmlSpace="preserve"
          >
            <pattern
              fill="transparent"
              y="328.3"
              width="8"
              height="8"
              patternUnits="userSpaceOnUse"
              id="SVGID_1_"
              viewBox="0 -8 8 8"
            >
              <g>
                <rect y="-8" className="st0" width="8" height="8" />
                <rect y="-8" className="st0" width="8" height="8" />
                <circle
                  className="st1"
                  cx="4"
                  cy="-4"
                  r="2"
                  fill="currentColor"
                />
              </g>
            </pattern>
            <pattern
              id="SVGID_2_"
              xlinkHref="#SVGID_1_"
              patternTransform="matrix(-0.848 0.5299 0.5299 0.848 -29701.4336 14788.4521)"
            ></pattern>
            <path
              className="st2"
              style={{ fill: "url(#SVGID_2_)" }}
              d="M60,0C45.8,6.1,29.7,19.5,10.7,43.1c-28.2,39.8,6.8,90.6,17.2,141.6c12,51.3,1.3,104,24.6,136.4 c2.5,2.7,5,5.1,7.4,7.2L60,0L60,0z"
            />
          </svg>
        </div>
        <Elements.BuiltWith background="page_image" image="page_image" />
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Pierce, { name: "Pierce" })
